(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-slider/views/banner-slider.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-slider/views/banner-slider.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_groups=_svs.banner_groups||{};
_svs.banner_groups.banner_slider=_svs.banner_groups.banner_slider||{};
_svs.banner_groups.banner_slider.templates=_svs.banner_groups.banner_slider.templates||{};
svs.banner_groups.banner_slider.templates.banner_slider = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "margin-bottom-1";
},"3":function(container,depth0,helpers,partials,data) {
    return "margin-bottom-2";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 class=\"widget-title "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inverted") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":45},"end":{"line":3,"column":78}}})) != null ? stack1 : "")
    + " f-center\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":3,"column":89},"end":{"line":3,"column":98}}}) : helper)))
    + "</h2>";
},"6":function(container,depth0,helpers,partials,data) {
    return "f-inverted";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"block "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inverted") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":43},"end":{"line":4,"column":76}}})) != null ? stack1 : "")
    + " f-500 f-medium margin-bottom-2 widget-subtitle f-center padding-xs-h-1\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"subTitle") || (depth0 != null ? lookupProperty(depth0,"subTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"subTitle","hash":{},"data":data,"loc":{"start":{"line":4,"column":149},"end":{"line":4,"column":161}}}) : helper)))
    + "</span>";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"slider-navigation-indicator-top\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"inverted") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "              \n          </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"navigation-indicator",{"name":"ui","hash":{"class":"js-navigation-indicator-top","branding":"inverted"},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":14},"end":{"line":12,"column":21}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"navigation-indicator/prev",{"name":"ui","hash":{"branding":"inverted"},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":70}}}))
    + "\n                "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"navigation-indicator/dots",{"name":"ui","hash":{"activeIndex":0,"numberOf":(depth0 != null ? lookupProperty(depth0,"numberOfPages") : depth0)},"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":87}}}))
    + "\n                "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"navigation-indicator/next",{"name":"ui","hash":{"branding":"inverted"},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":70}}}))
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"navigation-indicator",{"name":"ui","hash":{"class":"js-navigation-indicator-top"},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":14},"end":{"line":18,"column":21}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"navigation-indicator/prev",{"name":"ui","hash":{},"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":50}}}))
    + "\n                "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"navigation-indicator/dots",{"name":"ui","hash":{"activeIndex":0,"numberOf":(depth0 != null ? lookupProperty(depth0,"numberOfPages") : depth0)},"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":87}}}))
    + "\n                "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"navigation-indicator/next",{"name":"ui","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":50}}}))
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         <div class=\"visible-xs slider-navigation-indicator-bottom-wrapper\">\n           <div class=\"slider-navigation-indicator-bottom margin-xs-top-half\">\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"navigation-indicator",{"name":"ui","hash":{"class":"js-navigation-indicator-bottom"},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":13},"end":{"line":33,"column":20}}})) != null ? stack1 : "")
    + "           </div>\n         </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "               "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"navigation-indicator/dots",{"name":"ui","hash":{"activeIndex":0,"numberOf":(depth0 != null ? lookupProperty(depth0,"numberOfPages") : depth0)},"data":data,"loc":{"start":{"line":32,"column":15},"end":{"line":32,"column":86}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"banner-slider widget-full-height\">\n    <div class=\"slider-top relative "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"subTitle") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":36},"end":{"line":2,"column":97}}})) != null ? stack1 : "")
    + "\">\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":110}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"subTitle") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":175}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"numberOfPages") : depth0),">=",2,{"name":"compare","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":22,"column":20}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"js-banner-slider-group slider-container\">\n        <div class=\"js-slider-wrapper slider-wrapper slider-wrapper-snap grid-row\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"childElements") || (depth0 != null ? lookupProperty(depth0,"childElements") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"childElements","hash":{},"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":26,"column":31}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"numberOfPages") : depth0),">=",2,{"name":"compare","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":7},"end":{"line":36,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-banner_groups-banner_slider-banner_slider'] = svs.banner_groups.banner_slider.templates.banner_slider;
})(svs, Handlebars);


 })(window);