(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-slider/views/slider-page-wrapper.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-slider/views/slider-page-wrapper.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_groups=_svs.banner_groups||{};
_svs.banner_groups.banner_slider=_svs.banner_groups.banner_slider||{};
_svs.banner_groups.banner_slider.templates=_svs.banner_groups.banner_slider.templates||{};
svs.banner_groups.banner_slider.templates.slider_page_wrapper = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"sliderClasses") || (depth0 != null ? lookupProperty(depth0,"sliderClasses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"sliderClasses","hash":{},"data":data,"loc":{"start":{"line":1,"column":72},"end":{"line":1,"column":89}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='grid-row banner-slider-slide col-xs-12"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sliderClasses") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":50},"end":{"line":1,"column":96}}})) != null ? stack1 : "")
    + "'>\n  "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"sliderContentHtml") || (depth0 != null ? lookupProperty(depth0,"sliderContentHtml") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"sliderContentHtml","hash":{},"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":2,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});
Handlebars.partials['components-banner_groups-banner_slider-slider_page_wrapper'] = svs.banner_groups.banner_slider.templates.slider_page_wrapper;
})(svs, Handlebars);


 })(window);