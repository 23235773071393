(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-slider/assets/javascripts/render-component.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-slider/assets/javascripts/render-component.js');

'use strict';

function _asyncIterator(r) { var n, t, o, e = 2; for ("undefined" != typeof Symbol && (t = Symbol.asyncIterator, o = Symbol.iterator); e--;) { if (t && null != (n = r[t])) return n.call(r); if (o && null != (n = r[o])) return new AsyncFromSyncIterator(n.call(r)); t = "@@asyncIterator", o = "@@iterator"; } throw new TypeError("Object is not async iterable"); }
function AsyncFromSyncIterator(r) { function AsyncFromSyncIteratorContinuation(r) { if (Object(r) !== r) return Promise.reject(new TypeError(r + " is not an object.")); var n = r.done; return Promise.resolve(r.value).then(function (r) { return { value: r, done: n }; }); } return AsyncFromSyncIterator = function (r) { this.s = r, this.n = r.next; }, AsyncFromSyncIterator.prototype = { s: null, n: null, next: function () { return AsyncFromSyncIteratorContinuation(this.n.apply(this.s, arguments)); }, return: function (r) { var n = this.s.return; return void 0 === n ? Promise.resolve({ value: r, done: !0 }) : AsyncFromSyncIteratorContinuation(n.apply(this.s, arguments)); }, throw: function (r) { var n = this.s.return; return void 0 === n ? Promise.reject(r) : AsyncFromSyncIteratorContinuation(n.apply(this.s, arguments)); } }, new AsyncFromSyncIterator(r); }
const isServer = typeof exports === 'object';
let logger;
if (isServer) {
  const trinidad = require('trinidad-core').core;
  logger = trinidad.logger('banner-groups: banner-slider');
} else {
  logger = svs.core.log.getLogger('banner-groups: banner-slider');
}

async function renderComponent(data, widgetInfo, req, res) {

  let hbsTemplateCompiled;
  let hbsBannerWrapperCompiled;
  let hbsSliderPageWrapperCompiled;
  if (isServer) {
    const hbs = global.internalInstances.get('hbs');
    hbsTemplateCompiled = hbs.cache['components-banner_groups-banner_slider-banner_slider'];
    hbsBannerWrapperCompiled = hbs.cache['components-banner_groups-banner_slider-banner_wrapper'];
    hbsSliderPageWrapperCompiled = hbs.cache['components-banner_groups-banner_slider-slider_page_wrapper'];
  } else {
    hbsTemplateCompiled = svs.banner_groups.banner_slider.templates.banner_slider;
    hbsBannerWrapperCompiled = svs.banner_groups.banner_slider.templates.banner_wrapper;
    hbsSliderPageWrapperCompiled = svs.banner_groups.banner_slider.templates.slider_page_wrapper;
  }
  const gridSettings = 'col-xs-10 col-sm-7 col-am-4 col-md-3 col-lg-3 col-xl-3';
  let noOfBannersOnASlide = 1; 
  let childElements = ''; 
  let bannerItem = '';
  let sliderContentHtml = ''; 
  let i = 0;
  let y = 1;
  const setSliderSize = () => {
    switch (svs.core.detect.breakpoint.current) {
      case 'xs':
        noOfBannersOnASlide = 1;
        break;
      case 'sm':
        noOfBannersOnASlide = 1;
        break;
      case 'am':
        noOfBannersOnASlide = 3;
        break;
      case 'md':
        noOfBannersOnASlide = 4;
        break;
      case 'lg':
        noOfBannersOnASlide = 4;
        break;
      case 'xl':
        noOfBannersOnASlide = 4;
        break;
      default:
        noOfBannersOnASlide = 1;
    }
  };
  if (!isServer) {
    setSliderSize();
  }
  var _iteratorAbruptCompletion = false;
  var _didIteratorError = false;
  var _iteratorError;
  try {
    for (var _iterator = _asyncIterator(data.banners), _step; _iteratorAbruptCompletion = !(_step = await _iterator.next()).done; _iteratorAbruptCompletion = false) {
      const banner = _step.value;
      {
        if (banner.layoutType) {
          let bannerComponent;
          if (isServer) {
            bannerComponent = data.components["banner_layouts/".concat(banner.layoutType)];
          } else {
            bannerComponent = svs.banner_layouts[banner.layoutType.replace(/-/g, '_')];
          }
          try {
            const bannerHtml = await bannerComponent.renderComponent(banner, widgetInfo, i + 1, req, res);

            if (noOfBannersOnASlide <= 2) {
              bannerItem = hbsBannerWrapperCompiled({
                bannerHtml,
                gridSettings,
                sliderClasses: 'js-slider-slide slider-slide'
              });
              childElements = "".concat(childElements).concat(bannerItem);
            } else {
              bannerItem = hbsBannerWrapperCompiled({
                bannerHtml,
                gridSettings,
                sliderClasses: 'banner-slider-group-column'
              });
              sliderContentHtml = "".concat(sliderContentHtml).concat(bannerItem);

              if (y >= noOfBannersOnASlide || i >= data.banners.length - 1) {
                const sliderPage = hbsSliderPageWrapperCompiled({
                  sliderContentHtml,
                  sliderClasses: 'js-slider-slide slider-slide'
                });
                childElements = "".concat(childElements).concat(sliderPage);
                sliderContentHtml = '';
                y = 0;
              }
            }
          } catch (error) {
            logger.warn("Square banner skipped due to banner loading error: ".concat(error.toString()), req);
          }
        } else {
          logger.warn("Banner id ".concat(banner.id, " lacks layoutType property"));
        }
        i++;
        y++;
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (_iteratorAbruptCompletion && _iterator.return != null) {
        await _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }
  const model = {
    title: data.config.title,
    widgetId: data.config.widgetId,
    inverted: data.config.inverted,
    subTitle: data.config.subTitle,
    verticalMode: data.config.verticalMode,
    isMobile: data.config.isMobile,
    numberOfPages: noOfBannersOnASlide <= 2 ? 0 : Math.ceil(i / noOfBannersOnASlide),
    childElements
  };
  return hbsTemplateCompiled(model) || '';
}
if (isServer) {
  module.exports = {
    renderComponent
  };
} else {
  svs.banner_groups.banner_slider = {
    renderComponent
  };
}

 })(window);