(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-slider/views/banner-wrapper.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-slider/views/banner-wrapper.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_groups=_svs.banner_groups||{};
_svs.banner_groups.banner_slider=_svs.banner_groups.banner_slider||{};
_svs.banner_groups.banner_slider.templates=_svs.banner_groups.banner_slider.templates||{};
svs.banner_groups.banner_slider.templates.banner_wrapper = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"sliderClasses") || (depth0 != null ? lookupProperty(depth0,"sliderClasses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"sliderClasses","hash":{},"data":data,"loc":{"start":{"line":1,"column":33},"end":{"line":1,"column":50}}}) : helper)))
    + " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sliderClasses") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":58}}})) != null ? stack1 : "")
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"gridSettings") || (depth0 != null ? lookupProperty(depth0,"gridSettings") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"gridSettings","hash":{},"data":data,"loc":{"start":{"line":1,"column":58},"end":{"line":1,"column":74}}}) : helper)))
    + "\">\n  <div class=\"banner-slider-item\">\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"bannerHtml") || (depth0 != null ? lookupProperty(depth0,"bannerHtml") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bannerHtml","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":20}}}) : helper))) != null ? stack1 : "")
    + "\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-banner_groups-banner_slider-banner_wrapper'] = svs.banner_groups.banner_slider.templates.banner_wrapper;
})(svs, Handlebars);


 })(window);