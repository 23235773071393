(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-slider/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-slider/assets/javascripts/init.js');

'use strict';

const logger = svs.core.log.getLogger('banner-groups: banner-slider');
svs.banner_groups.banner_slider.init = options => {
  const $widgetEl = options.$el[0];
  if ($widgetEl) {
    var _$widgetEl$getAttribu;
    const widgetId = (_$widgetEl$getAttribu = $widgetEl.getAttribute('data-id')) === null || _$widgetEl$getAttribu === void 0 ? void 0 : _$widgetEl$getAttribu.replace(/-/g, '_');
    let slider;
    const initSlider = () => {
      const $bannerSliderInDom = $widgetEl.querySelector('.js-banner-slider-group');
      if (slider) {
        slider.destroy();
      }
      slider = new svs.components.Slider($bannerSliderInDom, {
        center: true,
        scrollSnap: true,
        bouncing: true
      });

      let navigationIndicatorTop;
      let navigationIndicatorBottom;
      if (navigationIndicatorTop) {
        navigationIndicatorTop.destroy();
      }
      if (navigationIndicatorBottom) {
        navigationIndicatorBottom.destroy();
      }
      const $navIndicatorTopEl = $widgetEl.querySelector('.js-navigation-indicator-top');
      if ($navIndicatorTopEl) {
        navigationIndicatorTop = new svs.ui.NavigationIndicator({
          el: $navIndicatorTopEl,
          onChange: index => {
            slider.scrollTo(index);
          }
        });
      }
      const $navIndicatorBottomEl = $widgetEl.querySelector('.js-navigation-indicator-bottom');
      if ($navIndicatorBottomEl) {
        navigationIndicatorBottom = new svs.ui.NavigationIndicator({
          el: $navIndicatorBottomEl
        });
      }
      slider.on('change', slide => {
        if (navigationIndicatorTop) {
          navigationIndicatorTop.setCurrentItem(slide.index);
        }
        if (navigationIndicatorBottom) {
          navigationIndicatorBottom.setCurrentItem(slide.index);
        }
      });
    };
    const clientSideReRender = async () => {
      const widgetData = svs.widgets.reRenderData && svs.widgets.reRenderData.data && svs.widgets.reRenderData.data["widg_".concat(widgetId)];
      if (widgetData) {
        return await svs.banner_groups.banner_slider.renderComponent(widgetData.bannerData, widgetData.widgetInfo);
      }
      logger.warn('Not able to use reRendering because of missing data');
      return null;
    };

    const clientSideReRenderEvent = () => {
      const event = new CustomEvent('clientSideReRender', {});
      window.dispatchEvent(event);
    };

    if (svs.core.detect.breakpoint.current !== 'xs') {
      clientSideReRender().then(value => {
        if (value) {
          $widgetEl.innerHTML = value;
        }
        initSlider();
        clientSideReRenderEvent();
      });
    } else {
      initSlider();
    }

    svs.core.detect.breakpoint.on('change', () => {
      clientSideReRender().then(value => {
        $widgetEl.innerHTML = value;
        initSlider();
        clientSideReRenderEvent();
      });
    });
  }
};

 })(window);